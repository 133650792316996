import {Box, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import SimpleTable from "../../../components/Common/Elements";
import ListJson from "../ListJson";
import TableJson from "../TableJson";
import {useState} from "react";
import {styled} from "@mui/system";

const DottedLink = styled(Typography)(({ theme }) => ({
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
        color: theme.palette.primary.dark,
    },
}));

export default function SimBuildings({buildings}) {

    const [showObjects, setShowObjects] = useState({})

    const toggleShowObj = (id) => {
        setShowObjects({
            ...showObjects,
            [id]: showObjects.hasOwnProperty(id)?!showObjects[id]:true
        })
    }

    return (
        <Box>

            <Table className={"striped"}>
                <TableRow>
                    <TableCell rowSpan={2}>Objects</TableCell>
                    <TableCell rowSpan={2}>id</TableCell>
                    <TableCell rowSpan={2}>Overtime</TableCell>
                    <TableCell rowSpan={2} >Level</TableCell>
                    <TableCell colSpan={4}>LevelUpTimer</TableCell>
                    <TableCell rowSpan={2} >Upgrades</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>StartTicks</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Estimation</TableCell>
                    <TableCell>Elapsed</TableCell>
                </TableRow>
                {Object.keys(buildings).map((key) => (
                    <>
                        <TableRow>
                            <TableCell>
                                <DottedLink onClick={() => toggleShowObj(key)}>expand</DottedLink>
                            </TableCell>
                            <TableCell>
                                {key}
                            </TableCell>
                            <TableCell>{buildings[key]['Overtime']}</TableCell>
                            <TableCell>{buildings[key]['Level']}</TableCell>
                            <TableCell>{buildings[key]['LevelUpTimer']['StartTicks']}</TableCell>
                            <TableCell>{buildings[key]['LevelUpTimer']['Duration']}</TableCell>
                            <TableCell>{buildings[key]['LevelUpTimer']['Estimation']}</TableCell>
                            <TableCell>{buildings[key]['LevelUpTimer']['Elapsed']}</TableCell>
                            <TableCell>{JSON.stringify(buildings[key]['Upgrades'])}</TableCell>

                        </TableRow>
                        {showObjects.hasOwnProperty(key)&&showObjects[key]===true&&<TableRow>
                            <TableCell colSpan={9}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell rowSpan={3} >
                                                ID
                                            </TableCell>
                                            <TableCell colSpan={6} align="center">
                                                Process
                                            </TableCell>
                                            <TableCell colSpan={6} align="center">
                                                LockedBy
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                Par
                                            </TableCell>
                                            <TableCell colSpan={3} align="center">
                                                Timer
                                            </TableCell>
                                            <TableCell colSpan={3} align="center">
                                                General Info
                                            </TableCell>
                                            <TableCell colSpan={3} align="center">
                                                Items
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>Process</TableCell>
                                            <TableCell>StartTicks</TableCell>
                                            <TableCell>Duration</TableCell>
                                            <TableCell>Elapsed</TableCell>
                                            <TableCell>Head</TableCell>
                                            <TableCell>Tail</TableCell>
                                            <TableCell>Cap</TableCell>
                                            <TableCell>Item</TableCell>
                                            <TableCell>Id</TableCell>
                                            <TableCell>Exist</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {Object.keys(buildings[key].Objects).map((objKey) => (
                                            <TableRow key={objKey}>
                                                <TableCell>{objKey}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].Process.Par[0]?.Type}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].Process.Par[0]?.Value}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].Process.Par[0]?.Process}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].Process.Timer.StartTicks}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].Process.Timer.Duration}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].Process.Timer.Elapsed}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].LockedBy.Head}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].LockedBy.Tail}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].LockedBy.Cap}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].LockedBy.Items[0]?.Item}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].LockedBy.Items[0]?.Id}</TableCell>
                                                <TableCell>{buildings[key].Objects[objKey].LockedBy.Items[0]?.Exist ? "True" : "False"}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableCell>
                        </TableRow>}
                    </>
                ))}
            </Table>

        </Box>
    );
}