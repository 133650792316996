import {
    Box,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import SimpleTable from "../../../components/Common/Elements";
import ListJson from "../ListJson";
import TableJson from "../TableJson";
import SimBuildings from "./SimBuildings";

export default function CitiesTable({cities}) {


    return (
        <>
            <Card sx={{mb:2}}>

                <Grid container spacing={2}>
                    <Grid sm={3} item>
                        <Typography sx={{pl:2, pt:2}} fontSize={24}>Current</Typography>
                        <TableJson item={cities} fields={["CityId","Stated","Completed"]} />
                    </Grid>
                </Grid>

            </Card>
            {Object.keys(cities['Cities']).map(key => (
                <>
                    <Typography sx={{pl:0, pt:1, pb:1}} variant="h4">{key}</Typography>

                    <Grid container spacing={2} >
                        <Grid item>
                            <Card>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Info
                                    </Typography>
                                    <TableJson item={cities['Cities'][key]} fields={["Completed","LatestQuest"]} />

                                </CardContent>

                            </Card>

                        </Grid>
                        <Grid item>
                            <Card>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Battle
                                    </Typography>
                                    {cities['Cities'][key].hasOwnProperty('Battle') && <TableJson item={cities['Cities'][key]['Battle']['PreferredHeroRoster']} fields={Object.keys(cities['Cities'][key]['Battle']['PreferredHeroRoster'])} />}


                                </CardContent>

                            </Card>
                        </Grid>
                        <Grid item sm={12}>
                            <Card>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        SIM: Buildings
                                    </Typography>
                                    {cities['Cities'][key].hasOwnProperty('Sim') && <SimBuildings buildings={cities['Cities'][key]['Sim']['Buildings']} />}

                                </CardContent>

                            </Card>
                        </Grid>
                    </Grid>
                </>
            ))}
        </>
    );
}