import {DataGrid} from "@mui/x-data-grid";
import {useGetPurchaseItemsTOHQuery, useGetSpendItemsTOHQuery} from "../../../core/api/toh-analytical-data-api";
import Loading from "../../../components/Common/Loading";

const columns = [
    { label: 'amount', field: 'amount', flex: 1 },
    { label: 'gemBalance', field: 'gemBalance', flex: 1 },
    { label: 'energyBalance', field: 'energyBalance', flex: 1 },
    // { label: 'rowFood', field: 'rowFood', flex: 1 },
    // { label: 'cookedFood', field: 'cookedFood', flex: 1 },
    // { label: 'furniture', field: 'furniture', flex: 1 },
    // { label: 'coal', field: 'coal', flex: 1 },
    // { label: 'log', field: 'log', flex: 1 },
    // { label: 'iron', field: 'iron', flex: 1 },
    // { label: 'board', field: 'board', flex: 1 },
    // { label: 'billet', field: 'billet', flex: 1 },
    // { label: 'rawFood', field: 'rawFood', flex: 1 },
    { label: 'productId', field: 'productId', flex: 1 },
    { label: 'cityId', field: 'cityId', flex: 1 },
    { label: 'mainQuest', field: 'mainQuest', flex: 1 },
    { label: 'category', field: 'category', flex: 1 },
    { label: 'balance', field: 'balance', flex: 1 },
    { label: 'reason', field: 'reason', flex: 1 },
    { label: 'item', field: 'item', flex: 1 },
    { label: 'sessionVersion', field: 'sessionVersion', flex: 1 },
    { label: 'timestamp', field: 'timestamp', flex: 1 },

]

const PurchaseTOH = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        isFetching,
        error
    } = useGetPurchaseItemsTOHQuery({
        userId: props.deviceId,
        limit: 200,
        page: 0
    })

    let payments = ""

    if (typeof data === "object") {

        payments = <DataGrid
            getRowId={(row) => row.timestamp}
            rows={data}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight

        />
    } else {
        payments = "no data"
    }

    return (
        <Loading
            isLoading={isLoading||isFetching}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            {payments}

        </Loading>
    )
}

export default PurchaseTOH